.form-container {
    position: relative; /* Ensures the positioning context is set to the form */
    padding-bottom: 50px; /* Space at the bottom to avoid the button overlapping anything below */
    margin-bottom: 20px;
}

.submit-button {
    position: absolute; /* Position relative to nearest positioned ancestor */
    right: 0; /* Aligns the button to the right */
    bottom: 10px; /* Distance from the bottom of the form container */
    padding: 10px 20px; /* Optional: Adjust padding to make button bigger */
    cursor: pointer; /* Cursor pointer for better UX */
    margin-right: 50px; /* Space between label and field */
}


.form-section {
    margin-bottom: 20px;
    margin: 5px 50px;
}

.form-label {
    display: inline-block;
    width: 20%; /* Adjust this percentage if necessary */
    text-align: right;
    margin-right: 10px; /* Space between label and field */
}
.form-labeln { 
    flex: 0 0 250px; /* Gives the label a fixed width, adjust as needed */
    text-align: right;
    margin-right: 20px; /* Spacing between label and the dropdown */
}

input[type="text"],
input[type="number"],
select {
    width: 98%; /* Ensure full width within their container */
    padding: 8px; /* Uniform padding */
    margin: 0px 0; /* Consistent margin for vertical spacing */
    box-sizing: border-box; /* Include padding and borders in width calculation */
    /* height: 40px; Define a specific height */
    font-size: 12px; /* Ensure text size is consistent */
    /* line-height: 1.5; Adjust line height to ensure content is vertically centered */
    border: 1px solid #ccc; /* Consistent border styling */
    border-radius: 4px; /* Optional: rounded corners */
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure full width or adjust as needed */
}
.input-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Takes the rest of the space in the .form-field-row */
}
.form-field {
    width: 100%; /* Adjust width based on your layout needs */
    box-sizing: border-box; /* Include padding and border in the element's width */
}
.label {
    align-self: flex-start; /* Aligns label at the top, unaffected by the size of other elements */
}
/* Ensure the label and field container align items properly */

.form-field-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;  /* Provides space between rows */
}



.mandatory {
    color: #ba066c;
    margin-left: 4px; /* Small space between label and asterisk */
}

textarea {
    width: 100%; /* Full width to match other inputs */
    padding: 8px; /* Padding for text alignment inside the textarea */
    border: 1px solid #ccc; /* Consistent border with other form fields */
    border-radius: 4px; /* Optional: Rounded corners */
    box-sizing: border-box; /* Include padding and borders in width and height calculations */
    min-height: 200px; /* Minimum height */
}

.additional-text {
    margin-top: 5px; /* Space between the dropdown and additional text */
    color: #666;  /* Lighter text color */
}

.error-message{
    margin-top: 5px; /* Space between the dropdown and additional text */
    color: #ba066c;  
}

@media (max-width: 768px) {
    .input-container {
        display: flex;
        width: 100%; 
        flex-direction: column;
        flex-grow: 1; /* Takes the rest of the space in the .form-field-row */
    }

    .form-field-row {
        flex-direction: column; /* Stack label and field vertically */
        align-items: flex-start; /* Align items to the start of the flex container */
    }

    .form-label {
        text-align: left;
        flex: 0 0 auto; /* Auto height, takes space as needed */
        width: 100%; 
        margin-right: 0; /* No need for right margin in stacked layout */
    }

    .form-field, .additional-text {
        width: 100%; /* Ensure inputs and text span the full width */
    }
}
