.Background{
    background-color: #f2f5f7;
}

.Mandatory {
    color: #ba066c;
    margin-left: 4px; /* Small space between label and asterisk */
}

.Navigation{
    position: sticky;
    top: 0;
    z-index: 1000; /* Make sure it stays on top of other content */
    display: flex;  /* Aligns the tabs horizontally */
    overflow-x: auto;  /* Allows horizontal scrolling on smaller screens */
    white-space: nowrap;  /* Keeps tabs in a single line */
    background-color: #f1f1f1;  /* Light grey background */
    background-color: white;
    padding: 10px 0;  /* Padding around the tabs */
    border-bottom: 2px solid #ccc;  /* Adds a border underneath the tabs */
}

.TabButton{
    display: inline-block;
    padding: 10px 20px;  /* Padding inside each tab */
    cursor: pointer;  /* Pointer cursor on hover */
    border: none;  /* No border for the button */
    background-color: inherit;  /* Takes background color from parent */
    outline: none;  /* Removes focus outline */
    transition: background-color 0.3s;  /* Smooth transition for background color */
    font-size: 15px;  /* Set font size */
    border-radius: 10px;  /* Rounded corners on the top */
    font-weight: bold;
    color: #556B82;
    margin-left: 20px;
}

.TabButton:hover { 
    color: #0775ec;
  }
  

/* when we add border, we reduce the padding by 2 from all sides in order to make sure that the button 
does not pop out too much */
  
.TabButton:active {
    border: 2px solid #0056b3 ;
    padding: 8px 18px;
  }

.FormContainer{
    background-color: #f2f5f7;
    position: relative; /* Ensures the positioning context is set to the form */
    padding-bottom: 50px; /* Space at the bottom to avoid the button overlapping anything below */
    padding-top: 20px;
    /* margin-bottom: 20px; */
}

.FormSection{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 60px;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid #ccc;
}

.FormSectionTitle{
    /* background-color: #f2f5f7;
    margin-bottom: 50px; */
    margin-left: 10px;
    margin-top: -40px;
    margin-bottom: 50px;
}

.FormFieldRow  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2px;  /* Provides space between rows */
}

.FormLabel {
    display: inline-block;
    width: 20%; /* Adjust this percentage if necessary */
    text-align: right;
    margin-right: 10px; /* Space between label and field */
    color: #8e8a8af7;
}

.InputContainer{
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Takes the rest of the space in the .form-field-row */
}

.FormField {
    width: 98%; /* Adjust width based on your layout needs */
    box-sizing: border-box; /* Include padding and border in the element's width */
}

@media (max-width: 768px) {
    .InputContainer {
        display: flex;
        width: 98%; 
        flex-direction: column;
        flex-grow: 1; /* Takes the rest of the space in the .form-field-row */
    }

    .FormFieldRow  {
        flex-direction: column; /* Stack label and field vertically */
        align-items: flex-start; /* Align items to the start of the flex container */
    }

    .FormLabel {
        text-align: left;
        flex: 0 0 auto; /* Auto height, takes space as needed */
        width: 98%; 
        margin-right: 0; /* No need for right margin in stacked layout */
    }

    .FormField, .additional-text {
        width: 100%; /* Ensure inputs and text span the full width */
    }
}